
import { useMemo } from 'react'
import { columns } from 'src/modules/ProjectEnquiry/ServicesRequests/components/columns'
import { DataTable } from 'src/modules/ProjectEnquiry/ServicesRequests/components/data-table'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './core/QueryResponseProvider'

type Props = {

}

const Main = () => {
    const apiResp = useQueryResponseData()

    const { state, updateState } = useQueryRequest()
    const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
        return {
            srNo: indx + 1,
            ...d,
        }
    }), [apiResp])

    return (
        <div className='p-4'>
            <DataTable columns={columns} data={tableData || []} />
        </div>
    )
}

const UploadBillsRequestMain = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default UploadBillsRequestMain