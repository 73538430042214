import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import UserEngagementAanlysisMain from './Dashboard/Analysis/UserEngagementAanlysisMain'


type Props = {}

const UserEngagementAdminRoutes = (props: Props) => {
    const ROUTES = APP_ROUTES.campaigns.subRoutes.user_engagement.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route index element={<UserEngagementAanlysisMain />} />
                <Route path={ROUTES.dashboard.route} element={<UserEngagementAanlysisMain />} />
                <Route path={ROUTES.userWiseReport.route} element={<UserEngagementAanlysisMain />} />
                <Route path={"*"} element={<Navigate to={"/" + APP_ROUTES.campaigns.subRoutes.user_engagement.fullRoute} />} />
            </Route>
        </Routes>
    )
}

export default UserEngagementAdminRoutes