import { Link } from 'react-router-dom'
import { checkDecimal, componentType, convertToCamelCaseNormal, EXTRACT_NUMBERS_REGEX, FormField, PROJECT_CATEGORY_TYPE_OPTIONS } from 'src/helpers'
import { Label, Separator } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { PROJECT_CATEGORY_TYPE, PROJECT_CATEGORY_WISE_FORM_FIELDS, PROJECT_OVERVIEW_FORM_FIELDS, ProjectOverview } from './modules'
import { GENERAL_PROJECT_INFO_FORM_FIELDS, generalProjectInfo, PROJECT_CATEGORY_WISE_FORM_FIELD, PROJECT_CATEGORY_WISE_FORM_STRUCTURE } from './modules2'


type Props = {
    data: any
}


const RenderViewComponent = ({ value, type, formField }: { type: componentType, value: string | number | undefined, formField: FormField }) => {

    if (formField.dataKey === "termPlan") {
        if (value && Number(value) > 10) {
            value = Number((value).toString()) / 12 + " Years"
        }
        else value = value + " Years"
    }

    return <div className='flex flex-col border-b'>
        <Label className='mb-1 text-xs  text-gray-500'>{formField.label}</Label>
        {formField.componentProps?.description ? <div className='text-xs text-gray-500'>{formField.componentProps.description}</div> : null}
        <div className='text-sm flex space-x-1 items-center'>
            {formField.componentProps?.inputPrefixIcon ? formField.componentProps?.inputPrefixIcon : null}
            <div className='font-semibold mb-1  '>
                {
                    value ? <>
                        {(['input', 'inputicon', 'options', 'date_picker', "textarea"].includes(type)) && <div className=' '>
                            {formField?.componentProps?.numberFormatted ? value && checkDecimal((value).toString().replace(EXTRACT_NUMBERS_REGEX, ""), true) : value?.toString().trim()}
                        </div>}
                        {type === "file_picker" && !formField.componentProps?.fileSelectionType && <Link to={value as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer'>View</div></Link>}
                        {type === "file_picker" && formField.componentProps?.fileSelectionType == "multi" && <div className='flex'>
                            {
                                Array.isArray(value) && value.length > 0 &&  value.map((d: any) => {
                                    return <Link to={d as string || "#"} target='_blank' ><div className='  text-blue-400 underline underline-offset-2 cursor-pointer me-2'>View</div></Link>
                                })
                            }
                        </div>}
                    </> : "-"
                }
            </div>
            {formField.componentProps?.inputSuffixIcon ? " " + formField.componentProps?.inputSuffixIcon : null}
        </div>
    </div>
}


const ReviewProjectForm = ({ data }: Props) => {

    return (
        <div className=' '>
            <ScrollArea className='h-[80vh] px-4 bg-gray-50'>
                {/* <div className=' uppercase text-primary text-sm mt-4 mb-2'>Project Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialProjectData).map((k: any) => {
                            const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Contact Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialContactDetails).map((k: any) => {
                            const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Location Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialLocationDetails).map((k: any) => {
                            const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Attachments</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>

                    {
                        Object.keys(initialAttachments).map((k: any) => {
                            const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            const attachment = data[formfield?.dataKey as keyof object]
                            return formfield && <div className='flex flex-col'>
                                <Label className='mb-1 uppercase text-xs  text-gray-500'>{formfield.label}</Label>
                                <div>
                                    {Array.isArray(attachment) && attachment.every((url: any) => typeof url === "string") ? <div className='flex flex-wrap'>{attachment.map((url: string) => <div className='mx-2'> <DocumentViwer url={url} /></div>)}</div> : null}
                                    {typeof attachment === "string" ? <DocumentViwer url={attachment} /> : null}
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Site Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialSiteData).map((k: any) => {
                            const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Power Consumption Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialPowerConsumptionData).map((k: any) => {
                            const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>
                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Finance Data</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(initialProjectFinanceData).map((k: any) => {
                            const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div> */}


                {/* <div className=' uppercase text-primary text-sm mt-4 mb-2'>Project Details</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(ProjectOverview).map((k: any) => {
                            const formfield: FormField | undefined = PROJECT_OVERVIEW_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            if (k === "imagesAttachments") {
                                return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                            }
                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                        })
                    }
                </div>

                {data.projectCategoryType && <>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>{convertToCamelCaseNormal(data.projectCategoryType)}</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(PROJECT_CATEGORY_TYPE[data.projectCategoryType as keyof object]).map((k: any) => {
                                let FORM_FIELDS: any = PROJECT_CATEGORY_WISE_FORM_FIELDS[data.projectCategoryType as keyof object]
                                if (FORM_FIELDS) {
                                    const formfield: FormField | undefined = FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                    if (k === "imagesAttachments") {
                                        return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                                    }
                                    return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={data[formfield?.dataKey]} type={formfield?.componentType} />
                                }
                            })
                        }
                    </div>
                </>} */}

                <div className=' uppercase text-primary text-sm mt-4 mb-2'>Project Details</div>
                <Separator className='my-2' />
                <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                    {
                        Object.keys(generalProjectInfo).map((k: any) => {
                            let formfield: any = GENERAL_PROJECT_INFO_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                            let value = data[formfield?.dataKey]

                            if (formfield?.dataKey == "channelPartnerId") {
                                value = data["channelPartnerName"]
                            }
                            else if (formfield?.dataKey == "coordinates") {
                                value = data[formfield?.dataKey] ? Object.values(data[formfield?.dataKey]).join(",") : ""
                            }

                            return formfield && formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={value} type={formfield?.componentType} />
                        })
                    }
                </div>

                {data.projectCategoryType && <>
                    {
                        PROJECT_CATEGORY_WISE_FORM_STRUCTURE[data.projectCategoryType as keyof object] && <>
                            {
                                Object.entries(PROJECT_CATEGORY_WISE_FORM_STRUCTURE[data.projectCategoryType as keyof object]).map(([key, objectKeys]) => {
                                    return <div>
                                        <div className=' uppercase text-primary text-sm mt-4 mb-2'>{key}</div>
                                        <Separator className='my-2' />
                                        <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                                            {
                                                Object.keys(objectKeys as keyof object).map((k: any) => {
                                                    let FORM_FIELDS: any = PROJECT_CATEGORY_WISE_FORM_FIELD[data.projectCategoryType as keyof object]
                                                    if (FORM_FIELDS) {
                                                        let formfield: any = FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                                        let value = data[formfield?.dataKey]
                                                        if (formfield && ["landOwnershipDocuments", "recentElectricityBills"].includes(k)) {
                                                            return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={value} type={formfield?.componentType} />
                                                        }
                                                        return formfield && <RenderViewComponent key={formfield?.dataKey} formField={formfield} value={value} type={formfield?.componentType} />
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </>
                    }

                </>}

            </ScrollArea>
        </div>

    )
}

export default ReviewProjectForm