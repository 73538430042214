
import { useMemo, useState } from 'react'
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { columns } from 'src/modules/Users/AllUsers/components/columns'
import { DataTable } from 'src/modules/Users/AllUsers/components/data-table'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './core/QueryResponseProvider'
import DateInput from 'src/shadcn/components/custom/DateInpute/DateInput'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

type Props = {

}

const Main = () => {
    const apiResp = useQueryResponseData()
    const { state, updateState } = useQueryRequest()
    const [value, onChange] = useState<Value>([dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()]);
    const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
        return {
            srNo: indx + 1,
            ...d,
        }
    }), [apiResp])

    return (
        <div className='p-4'>
            <DataTable columns={columns} data={tableData || []} />
        </div>
    )
}

const AllUsersByRole = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}
export default AllUsersByRole