import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import dayjs from "dayjs";
import { Badge } from "src/shadcn/components/ui";
import Actions from "./Actions";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
  },
  {
    accessorKey: "mobileNumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Mobile Number" />
    ),
  },
  {
    accessorKey: "seen",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Seen" />
    ),
    cell({ row: { original: { seen } } }) {
      return seen ? <Badge variant={'outline_success'}>Yes</Badge> : <Badge variant={'outline_destructive'}>No</Badge>
    },
  },
  {
    accessorKey: "clickedOnJoinUs",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Interested in Registration" />
    ),
    cell({ row: { original: { clickedOnJoinUs } } }) {
      return clickedOnJoinUs ? <Badge variant={'outline_success'}>Yes</Badge> : <Badge variant={'outline_destructive'}>No</Badge>
    },
  },
  {
    accessorKey: "clickedOnExploreMarketplace",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Interested in Explore Marketplace" />
    ),
    cell({ row: { original: { clickedOnExploreMarketplace } } }) {
      return clickedOnExploreMarketplace ? <Badge variant={'outline_success'}>Yes</Badge> : <Badge variant={'outline_destructive'}>No</Badge>
    },
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell({ row: { original: { createdAt, comments } } }) {
      return <div className="flex flex-col space-y-1">
        <div>{dayjs(createdAt).format('DD/MM/YYYY')}</div>
        {/* <div className="text-xs text-gray-500">{comments?.length > 0 ? "updated At: " + dayjs(comments[comments?.length - 1].createdAt).format('YYYY-MM-DD') : ""}</div> */}
      </div>
    },
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Updated At" />
    ),
    cell({ row: { original: { createdAt, comments } } }) {
      return <div className="flex flex-col space-y-1">
        <div className="t">{comments?.length > 0 ?  dayjs(comments[comments?.length - 1].createdAt).format('DD/MM/YYYY') : ""}</div>
      </div>
    },
  },
  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      return <Actions row={original} />
    },
  },



];
