import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import UserEngagementAdminRoutes from './UserEngagments/UserEngagementAdminRoutes'
import UploadBillsRequestMain from './UplodedBills/UploadBillsRequestMain'



type Props = {}

const CampaignsAdminRoutes = (props: Props) => {
    const ROUTES = APP_ROUTES.campaigns.subRoutes

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route path={ROUTES.user_engagement.route + "/*"} element={<UserEngagementAdminRoutes />} />
                <Route path={ROUTES.bills.route} element={<UploadBillsRequestMain />} />
            </Route>
        </Routes>
    )
}

export default CampaignsAdminRoutes