import { ColumnDef } from "@tanstack/react-table";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import dayjs from "dayjs";

const TITLE_MAPPING = {
  projectId: "Project ID",
  projectSize: "Project Size",
  projectType: "Project Type",
  serviceName: "Service Name",
  state: "State",
  projectCategoryType: "Project Category Type",
  projectLocation: "Project Location",
  customerType: "Customer Type",
  orderType: "Order Type",
  createdAt: "Date",
  channelPartnerName: "Channel Partner Name",
};


export const dashboard_columns: ColumnDef<Task>[] = [
  ...Object.entries(TITLE_MAPPING).flatMap(([key, title]: [any, any]) => {
    if (typeof title === 'string') {
      if (key == 'createdAt') {
        return {
          accessorKey: key,
          header: ({ column }: any) => (
            <DataTableColumnHeader column={column} title={title} />
          ),
          cell({ row: { original: { createdAt } } }: any) {
            return dayjs(createdAt).format('DD/MM/YYYY')
          },
        };
      }
      else return {
        accessorKey: key,
        header: ({ column }: any) => (
          <DataTableColumnHeader column={column} title={title} />
        ),
      };
    }
    return [];
  }),

];
