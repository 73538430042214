import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import Actions from "./Actions";


export const columns: ColumnDef<Task>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
  },
  {
    accessorKey: "phonenumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Phone Number" />
    ),
  },
  {
    accessorKey: "preferred_time_to_call",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Preferred Date-Time" />
    ),
    cell({ row: { original: { preferred_time_to_call } } }) {
      return <div>{preferred_time_to_call}</div>
    },
  },
  {
    accessorKey: "preferred_time_to_call",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Preferred Date-Time" />
    ),
    cell({ row: { original: { preferred_time_to_call } } }) {
      return <div>{preferred_time_to_call}</div>
    },
  },
  {
    accessorKey: "scheduled_date",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
  },
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell({ row: { original: { createdAt } } }) {
      return <div>{dayjs(createdAt).format('DD/MM/YYYY')}</div>
    },
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Updated At" />
    ),
    cell({ row: { original: { createdAt, comments } } }) {
      return <div className="flex flex-col space-y-1">
        <div className="t">{comments?.length > 0 ? dayjs(comments[comments?.length - 1].createdAt).format('DD/MM/YYYY') : ""}</div>
      </div>
    },
  },

  {
    id: "Actions",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Actions" />
    ),
    cell({ row: { original } }) {
      const { call_status } = original
      return <Actions row={original} />
    },
  },

];
