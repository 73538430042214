import { checkDecimal } from 'src/helpers'
import { Card, CardContent, CardHeader, CardTitle } from 'src/shadcn/components/ui'

type Props = {
    title: string,
    value: string | any,
    icon?: any,
    prefix: any
    description?: string,
    action?: any
}

const AnalysisTiles = ({ title, value, icon, description, prefix, action }: Props) => {
    return (
        <div onClick={action}>
            <Card className='shadow-none'>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium text-gray-500 w-full">
                        {Array.isArray(value) ?
                            <div className='flex justify-between'>
                                <div>{title}</div>
                                <div># {value.reduce((acc, item) => {
                                    return acc + item.value
                                }, 0)}</div>
                            </div>
                            : title}
                    </CardTitle>
                    <div>{icon}</div>
                </CardHeader>
                <CardContent>
                    {
                        Array.isArray(value) && value.length > 0 ? <div className='flex justify-between'>
                            {
                                value.map((data: any) => {
                                    return <div>
                                        <span className='text-gray-500'>{data?.title}</span>
                                        <div className="text-xl space-x-2 "><span className='text-gray-400'>{data?.prefix}</span> <span className='font-bold'>{data?.value ? data?.value && checkDecimal(data?.value, true) : 0}</span></div>
                                    </div>
                                })
                            }
                        </div> : <div className="text-2xl space-x-2 "><span className='text-gray-400'>{prefix}</span> <span className='font-bold'>{value ? value && checkDecimal(value, true) : 0}</span></div>
                    }

                    <p className="text-xs text-muted-foreground">
                        {description}
                    </p>
                </CardContent>
            </Card>
        </div>

    )
}

export default AnalysisTiles