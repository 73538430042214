
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { columns } from 'src/modules/Projects/AllProjects/Table/components/columns';
import { DataTable } from 'src/modules/Projects/AllProjects/Table/components/data-table';
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider';
import { QueryResponseProvider, useQueryResponseData } from './core/QueryResponseProvider';

type Props = {
    filter: any
}

const Main = ({ filter }: Props) => {
    const apiResp = useQueryResponseData()
    const { state, updateState } = useQueryRequest()

    useEffect(() => {
        if (filter) {
            updateState({
                filter: filter
            })
        }

        return () => { }
    }, [filter])


    const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
        return {
            srNo: indx + 1,
            ...d,
        }
    }), [apiResp])


    console.log({ state: state.filter });


    return (
        <div className='p-4'>
            <DataTable columns={columns} data={tableData || []} />
        </div>
    )
}

const AllProjectLIstByUserMain = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main {...props} />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default AllProjectLIstByUserMain