import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import AllUserLIstMain from './UserList/AllUserLIstMain'
import UserDashboardMain from './UserDashboard/UserDashboardMain'
import AllUsersByRole from './UsersByRole/AllUsersByRole'
import UserDetailsMain from './UserDetails/UserDetailsMain'


type Props = {}

const AdminUsersRoutes = (props: Props) => {
  const PROJECTS_ROUTES = APP_ROUTES.users
  const PROJECT_SUB_ROUTES = PROJECTS_ROUTES.subRoutes

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<AllUsersByRole />} />
        <Route path={PROJECT_SUB_ROUTES.dashboard.route} element={<UserDashboardMain />} />
        <Route path={PROJECT_SUB_ROUTES.all.route} element={<AllUserLIstMain />} />
        <Route path={PROJECT_SUB_ROUTES.users.route} element={<AllUserLIstMain />} />
        <Route path={PROJECT_SUB_ROUTES.details.route + "/:id"} element={<UserDetailsMain />} />
        <Route path={"*"} element={<Navigate to={"/" + PROJECT_SUB_ROUTES.dashboard.fullRoute} />} />
      </Route>
    </Routes>
  )
}

export default AdminUsersRoutes