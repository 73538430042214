import { Plus } from 'lucide-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { columns } from 'src/modules/Campaigns/UserEngagments/UserWiseReport/Table/Component/columns'
import { DataTable } from 'src/modules/Campaigns/UserEngagments/UserWiseReport/Table/Component/data-table'
import { Button } from 'src/shadcn/components/ui'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData, useQueryResponseLoading } from './core/QueryResponseProvider'

type Props = {}


const Main = () => {

    const navigate = useNavigate()
    const apiResp = useQueryResponseData()
    const isLoading = useQueryResponseLoading()
    const tableData = useMemo(() => {
        if (apiResp && Array.isArray(apiResp) && apiResp.length > 0) {
            return apiResp.map((d: any, indx: number) => {
                return ({
                    ...d,
                    srNo: (indx + 1)
                })
            })
        }
        else return []
    }, [apiResp])

    return <div className='mt-4 space-y-2'>
        <div className='uppercase text-primary'>USer Wise Report </div>
        <DataTable data={tableData || []} columns={columns} isLoading={isLoading} />
    </div >

}

const UserWiseReportMainPage = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default UserWiseReportMainPage