import dayjs from 'dayjs'
import { ChangeEvent, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, convertArrayToOptionType, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { useAuth } from 'src/modules/Auth/Core'
import { useQueryResponse } from 'src/pages/ProjectEnquiry/FromEmail/core/QueryResponseProvider'
import { createEmailRequestCommentApiRequest, createNewsLetterCommentRequestCommentApiRequest, updateScheduleCallRequestApiRequest } from 'src/services/requests/ProjectEnquiry'
import CustomeDialog from 'src/shadcn/components/custom/CustomeDialog'
import Dropdown from 'src/shadcn/components/custom/Dropdown'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { Button, Label, Textarea } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { Task } from '../data/schema'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

type Props = {
    row: Task
}

const Actions = ({ row }: Props) => {

    const navigate = useNavigate()
    const { currentUser } = useAuth()
    const { refetch } = useQueryResponse()
    const [openDialog, setopenDialog] = useState<boolean>(false)
    const [remark, setremark] = useState<string>('')
    const [remarkError, setRemarkError] = useState<string>('')

    const [isLoading, setisLoading] = useState<boolean>(false)

    const handleUpdateRemark = catchAsync(async () => {
        setisLoading(true)
        return await createNewsLetterCommentRequestCommentApiRequest(row._id, {
            "message": remark,
        })
    }, (result: any) => {
        setisLoading(false)
        if (IS_SUCCESS_RESPONSE(result)) {
            setopenDialog(false)
            refetch()
        }
    }, () => {
        setisLoading(false)
    })

    const handleValidateForm = () => {
        let remarkCheck = false


        if (remark) {
            if (remark.length <= 250) {
                remarkCheck = true
            }
            else setRemarkError("Max length 250 characters only")
        } else setRemarkError("Remark required")


        return remarkCheck

    }

    const lastComment = useMemo(() => row?.comments?.length > 0 ? dayjs(row?.comments[row?.comments?.length - 1].createdAt).format('YYYY-MM-DD') : "", [row?.comments])
    console.log({ lastComment });


    return (
        <div className='flex space-x-2'>

            {/* <ToolTip tooltipContent='View project'>
                <Button size={"sm"} variant={'primaryIconBtn'} onClick={() => navigate("/" + APP_ROUTES.projects.subRoutes.view.fullRoute + "/" + row._id)} >
                    <Eye size="16" />
                </Button>
            </ToolTip> */}
            {/* <Button variant={'outlinePrimary'} size={'xs'} onClick={() => setopenDialog(true)} >Comment</Button> */}
            <div className='flex flex-col space-y-1 '>
                <Button variant={'outlinePrimary'} size={'xs'} onClick={() => setopenDialog(true)} >Comment</Button>
                {/* <div className='text-xs text-gray-500'>{lastComment ? "" + dayjs(lastComment).fromNow() : ''}</div> */}
            </div>
            <CustomeDialog className='md:max-w-sl' isOpen={openDialog} submitBtnTitle='Submit' headerTitle={"Comments"} onCancle={() => setopenDialog(false)}
                onSubmit={() => {
                    if (handleValidateForm()) {
                        handleUpdateRemark()
                    }
                }}
            >
                <div className='pb-4'>
                    <div className='pb-4'>
                        <ScrollArea className='max-h-[300px] flex flex-col '>
                            {row.comments?.length > 0 && row.comments.map(({ message, createdAt }) => {
                                return <div className=' flex flex-col bg-gray-50 p-1 border-l-4 my-1 border-gray-500'>
                                    <div>{message}</div>
                                    <div className='text-gray-400 text-xs'>{dayjs(createdAt).format(' DD/MM/YYYY HH:mm')}</div>
                                </div>
                            })}
                        </ScrollArea>
                    </div>
                    <Label className='pb-2'>Comment </Label>
                    <Textarea maxLength={250} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setRemarkError("")
                        setremark(e.target.value)
                    }} ></Textarea>
                    {remarkError && <p className='text-xs text-destructive '>{remarkError}</p>}



                </div>
            </CustomeDialog>
            <LoadingDialog isOpen={isLoading} />

        </div>
    )
}

export default Actions