import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { IRoutes, ROUTES } from 'src/helpers/routes'
import EpcContractorDetail from 'src/modules/EpcContractors/EpcProfile/EpcContractorDetail'
import AppLayoutMain from 'src/modules/Layouts/App/AppLayoutMain'
import CoupensRoutespage from 'src/pages/Coupens/CoupensRoutespage'
import DashboardMain from 'src/pages/Dashboard/DashboardMain'
import EnquiryRequestsRoute from 'src/pages/EnquiryRequest/EnquiryRequestsRoute'
import AllEpcContractorsMain from 'src/pages/EpcContractors/AllEpcContractors/AllEpcContractorsMain'
import EpcContractorCreateMain from 'src/pages/EpcContractors/Profile/EpcContractorCreateMain'
import EpcContractorUpdateMain from 'src/pages/EpcContractors/Profile/EpcContractorUpdateMain'
import EpcContractorUpdateMainAdmin from 'src/pages/EpcContractors/Profile/EpcContractorUpdateMainAdmin'
import InvestorsRoute from 'src/pages/investors/InvestorsRoute'
import LandOnboardingAdminRoutes from 'src/pages/LandOnbaordRequests/LandOnboardingAdminRoutes'
import AdminManufacturerRoutes from 'src/pages/Manufacturer/AdminManufacturerRoutes'
import AdminOrderRoutes from 'src/pages/Orders/AdminOrderRoutes'
import P2pEnquiryRequestsRoute from 'src/pages/P2pEnquiryRequest/P2pEnquiryRequestsRoute'
import PaymentsRoutepage from 'src/pages/Payments/PaymentsRoutepage'
import ProfileMain from 'src/pages/Profile'
import AdminProjectEnquiryRoutes from 'src/pages/ProjectEnquiry/AdminProjectEnquiryRoutes'
import ProjectsRoute from 'src/pages/Projects/ProjectsRoute'
import AdminQuatioRequestsRoutes from 'src/pages/QuotationRequests/AdminQuatioRequestsRoutes'
import RefralRoutePage from 'src/pages/Refral/RefralRoutePage'
import SalesExecutiveRoutes from 'src/pages/SalesExecutives/SalesExecutiveRoutes'
import NewConstructedProjectsAdminRoutes from 'src/pages/Services/NewConstructedProjects/NewConstructedProjectsAdminRoutes'
import ProjectRightsAdminRoutes from 'src/pages/Services/ProjectRights/ProjectRightsAdminRoutes'
import RooftopAdminRoutes from 'src/pages/Services/Rooftop/RooftopAdminRoutes'
import SellExistingProjectsAdminRoutes from 'src/pages/Services/SellExistingProjects/SellExistingProjectsAdminRoutes'
import WalletsRoutePage from 'src/pages/Wallets/WalletsRoutePage'
import { APP_ROUTES } from './modules'
import AdminUsersRoutes from 'src/pages/Users/AdminUsersRoutes'
import CampaignsAdminRoutes from 'src/pages/Campaigns/CampaignsAdminRoutes'

type Props = {}

type RenderRoutesProps = {
  route: IRoutes,
  role: string
}

const RenderRoutes = ({ route, role }: RenderRoutesProps) => {

  const Component = lazy(() => import(route.componentPath));

  return <>
    {
      (
        <Route
          key={"route-" + route.fullPath}
          path={route.routePath}
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Component />
            </Suspense>
          }
        />
      )
    }
  </>

  if (route.roles.includes(role)) {

    // if (route.subRoutes && route.subRoutes.length > 0) {
    //   return <Route path={route.routePath} element={
    //     <Routes>
    //       <Route element={<Outlet />}>
    //         <Route index element={<Navigate to={route.indexRoute || "/"} />} />
    //         {
    //           route.subRoutes.map((d: IRoutes) => {
    //             return <RenderRoutes route={d} role={role} />
    //           })
    //         }
    //       </Route>
    //     </Routes>
    //   } />
    // }
    // else {
    return <Route
      key={"route-" + route.fullPath}
      path={route.routePath}
      element={
        <Suspense fallback={<div>Loading...</div>}>
          <Component />
        </Suspense>
      }
    />
    // }
  }
  else return null
};


const AppRoutes = (props: Props) => {

  const userRole = "admin"
  return (
    <Routes>
      <Route element={<AppLayoutMain />} >
        <Route index element={<Navigate to={ROUTES.dashboard.path} />} />
        <Route path={APP_ROUTES.dashboard.route} element={<DashboardMain />} />
        <Route path={APP_ROUTES.epc_contractors.route} element={<AllEpcContractorsMain />} />
        <Route path={APP_ROUTES.epc_contractors.subRoutes.edit.fullRoute + "/:id"} element={<EpcContractorUpdateMainAdmin />} />
        <Route path={APP_ROUTES.epc_contractors.subRoutes.view.fullRoute + "/:id"} element={<EpcContractorDetail />} />
        <Route path={APP_ROUTES.epc_contractors.subRoutes.create.fullRoute} element={<EpcContractorCreateMain />} />
        <Route path={APP_ROUTES.epc_contractors.subRoutes.edit.fullRoute} element={<EpcContractorUpdateMain />} />
        <Route path={APP_ROUTES.projects.route + "/*"} element={<ProjectsRoute />} />
        <Route path={APP_ROUTES.sales_executive.route + "/*"} element={<SalesExecutiveRoutes />} />
        <Route path={APP_ROUTES.investors.route + "/*"} element={<InvestorsRoute />} />
        <Route path={APP_ROUTES.profile.route} element={<ProfileMain />} />
        <Route path={APP_ROUTES.manufacturers.route + "/*"} element={<AdminManufacturerRoutes />} />
        <Route path={APP_ROUTES.orders.route + "/*"} element={<AdminOrderRoutes />} />
        <Route path={APP_ROUTES.quotationRequest.route + "/*"} element={<AdminQuatioRequestsRoutes />} />
        <Route path={APP_ROUTES.landOnboard.route + "/*"} element={<LandOnboardingAdminRoutes />} />
        <Route path={APP_ROUTES.rooftop.route + "/*"} element={<RooftopAdminRoutes />} />
        <Route path={APP_ROUTES.newConstructedProjects.route + "/*"} element={<NewConstructedProjectsAdminRoutes />} />
        <Route path={APP_ROUTES.projectRights.route + "/*"} element={<ProjectRightsAdminRoutes />} />
        <Route path={APP_ROUTES.sellExistingProjects.route + "/*"} element={<SellExistingProjectsAdminRoutes />} />
        <Route path={APP_ROUTES.enquiryRequests.route + "/*"} element={<EnquiryRequestsRoute />} />
        <Route path={APP_ROUTES.p2pRequests.route + "/*"} element={<P2pEnquiryRequestsRoute />} />
        <Route path={APP_ROUTES.p2pRequests.route + "/*"} element={<P2pEnquiryRequestsRoute />} />
        <Route path={APP_ROUTES.referral.route + "/*"} element={<RefralRoutePage />} />
        <Route path={APP_ROUTES.wallets.route + "/*"} element={<WalletsRoutePage />} />
        <Route path={APP_ROUTES.coupen.route + "/*"} element={<CoupensRoutespage />} />
        <Route path={APP_ROUTES.payment.route + "/*"} element={<PaymentsRoutepage />} />
        <Route path={APP_ROUTES.projectEnquiry.route + "/*"} element={<AdminProjectEnquiryRoutes />} />
        <Route path={APP_ROUTES.users.route + "/*"} element={<AdminUsersRoutes />} />
        <Route path={APP_ROUTES.campaigns.route + "/*"} element={<CampaignsAdminRoutes />} />
       
        {/* {
          Object.entries(ROUTES).map(([key, value], index) => {
            return <RenderRoutes route={value as any} role={userRole} />
          })
        } */}
      </Route>
    </Routes>
  )
}

export default AppRoutes