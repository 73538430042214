import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance, axiosInstanceWithoutAuth } from "src/services/axiosSetup";
import * as API from "src/services/consts/ProjectEnquiry";

export function allProjectEnquiryRooftopRequestsApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.PROJECT_ENQUIRY_REQUEST_URL_ROOFTOP}`).then((d: AxiosResponse) => d.data)
}

export function allProjectEnquirySellProjectRequestsApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.PROJECT_ENQUIRY_REQUEST_URL_SELL_PROJECT}`).then((d: AxiosResponse) => d.data)
}

export function allProjectEnquiryNewConstructionRequestsApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.PROJECT_ENQUIRY_REQUEST_URL_NEW_CONSTRUCTION}`).then((d: AxiosResponse) => d.data)
}

export function allProjectEnquiryLandRequestsApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.PROJECT_ENQUIRY_REQUEST_URL_LAND}`).then((d: AxiosResponse) => d.data)
}

export function projectEnquiryDashboardApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.PROJECT_ENQUIRY_DASHBOARD_URL}`).then((d: AxiosResponse) => d.data)
}


export function fetchScheduleCallRequestSummaryApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.SCHEDULE_CALL_REQUESTS_SUMMARY}`).then((d: AxiosResponse) => d.data)
}

export function fetchEmailSummaryApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.EMAIL_REQUESTS_SUMMARY}`).then((d: AxiosResponse) => d.data)
}


export function fetchProjectInterestSummaryApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.PROJECT_INTEREST_REQUEST_SUMMARY}`).then((d: AxiosResponse) => d.data)
}

export function fetchProjectServicesSummaryApiRequest(): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.PROJECT_SERVICES_REQUEST_SUMMARY}`).then((d: AxiosResponse) => d.data)
}

export function allScheduleCallRequestsApiRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? `?${query}` : query
    return axiosInstance.get(`${API.SCHEDULE_CALL_REQUESTS_BASE_URL}${query}`).then((d: AxiosResponse) => d.data)
}

export function allProjectInterestedRequestsApiRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? `?${query}` : query
    return axiosInstance.get(`${API.ALL_PROJECT_INTEREST_REQUEST}${query}`).then((d: AxiosResponse) => d.data)
}


export function updateScheduleCallRequestApiRequest(id:string,data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.put(`${API.ALL_PROJECT_UPDATE_SCHEDULE_CALL_REQUEST}/${id}`, data).then((d: AxiosResponse) => d.data)
}

export function createEmailRequestCommentApiRequest(id:string,data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.put(`${API.CREATE_EMAIL_REQUEST_COMMENT}/${id}`, data).then((d: AxiosResponse) => d.data)
}


export function createNewsLetterCommentRequestCommentApiRequest(id:string,data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.put(`${API.CREATE_NEWSLETTERS_REQUEST_COMMENT}/${id}`, data).then((d: AxiosResponse) => d.data)
}




export function fetchAllProjectServiceRequestApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_PROJECT_SERVICES_REQUEST}`, data).then((d: AxiosResponse) => d.data)
}


export function fetchAllUploadBillsRequestApiRequest(data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.get(`${API.ALL_UPLOAD_BIlls}`, data).then((d: AxiosResponse) => d.data)
}


export function allFromEmailRequestsApiRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? `?${query}` : query
    return axiosInstance.get(`${API.ALL_EMAIL_REPORT_REQUEST}${query}`).then((d: AxiosResponse) => d.data)
}

export function allNewsLettersReportRequestsApiRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? `?${query}` : query
    return axiosInstance.get(`${API.ALL_NEWSLETTERS_REPORT_REQUEST}${query}`).then((d: AxiosResponse) => d.data)
}

export function raisedEnquiryRequestsApiRequest(query: string): Promise<AxiosResponse<any>> {
    query = query ? `?${query}` : query
    return axiosInstance.get(`${API.RAISED_ENQUIRY_REPORT_REQUEST}${query}`).then((d: AxiosResponse) => d.data)
}


export function createRaisedEnquiryRequestCommentApiRequest(id:string,data: any): Promise<AxiosResponse<any>> {
    return axiosInstance.put(`${API.CREATE_RAISED_ENQUIRY_REQUEST_COMMENT}/${id}`, data).then((d: AxiosResponse) => d.data)
}