import { BASE_URL } from "../api_const";

export const PROJECT_ENQUIRY_REQUEST_BASE_URL = BASE_URL + "/whatsapp"
export const PROJECT_ENQUIRY_REQUEST_URL_ROOFTOP = PROJECT_ENQUIRY_REQUEST_BASE_URL + "/rooftop/enquiry"
export const PROJECT_ENQUIRY_REQUEST_URL_LAND = PROJECT_ENQUIRY_REQUEST_BASE_URL + "/land/enquiry"
export const PROJECT_ENQUIRY_REQUEST_URL_NEW_CONSTRUCTION = PROJECT_ENQUIRY_REQUEST_BASE_URL + "/newconstruction/enquiry"
export const PROJECT_ENQUIRY_REQUEST_URL_SELL_PROJECT = PROJECT_ENQUIRY_REQUEST_BASE_URL + "/sellproject/enquiry"
export const PROJECT_ENQUIRY_DASHBOARD_URL = PROJECT_ENQUIRY_REQUEST_BASE_URL + "/enquiry/count"

export const SCHEDULE_CALL_REQUESTS_BASE_URL = BASE_URL + "/callback-requests"
export const SCHEDULE_CALL_REQUESTS_SUMMARY= BASE_URL + "/get/callback_request/summary"
export const EMAIL_REQUESTS_SUMMARY= BASE_URL + "/email/count"
export const ALL_SCHEDULE_CALL_REQUESTS= SCHEDULE_CALL_REQUESTS_BASE_URL + "/get/callback_request"
export const PROJECT_INTEREST_REQUEST_SUMMARY = BASE_URL + "/get/project/capture_interest/count"
export const PROJECT_SERVICES_REQUEST_SUMMARY = BASE_URL + "/get/capture_interest/summary"
export const ALL_PROJECT_INTEREST_REQUEST = BASE_URL + "/get/project/capture_interest/details"
export const ALL_PROJECT_UPDATE_SCHEDULE_CALL_REQUEST = BASE_URL + "/callback-request/comment/create"
export const CREATE_EMAIL_REQUEST_COMMENT = BASE_URL + "/email/comment/create"
export const ALL_PROJECT_SERVICES_REQUEST = BASE_URL + "/get/capture_interest/details"
export const ALL_EMAIL_REPORT_REQUEST = BASE_URL + "/email/report"
export const ALL_NEWSLETTERS_REPORT_REQUEST = BASE_URL + "/newsletter"
export const CREATE_NEWSLETTERS_REQUEST_COMMENT = BASE_URL + "/newsletter/comment"
export const ALL_UPLOAD_BIlls = BASE_URL + "/bills"
export const RAISED_ENQUIRY_REPORT_REQUEST = BASE_URL + "/contact_us"
export const CREATE_RAISED_ENQUIRY_REQUEST_COMMENT = BASE_URL + "/contact_us/comment"
