import { BASE_URL } from "../api_const"

export const URL_LOGIN = BASE_URL + "/adminlogin"
export const URL_REGISTRATION = BASE_URL + "/createregister"
export const URL_FORGOT_PASSWORD = BASE_URL + "/admin/forgot-password"
export const URL_VERIFY_OTP = BASE_URL + "/admin/verfiy-otp"
export const URL_RESEND_OTP = BASE_URL + "/admin/resendotp"
export const URL_RESET_PASSWORD = BASE_URL + "/admin/resetpassword"
export const URL_CREATE_USER = BASE_URL + "/manufacturer/user/create"
export const URL_GET_USER_BY_TOKEN = BASE_URL + "/admin/byGetToken"
export const URL_ALL_USERS_FOR_DRPDWN =  '/admin/role/data'
