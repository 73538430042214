
import { useEffect, useMemo, useState } from 'react'
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { columns } from 'src/modules/Users/AllUsers/components/columns'
import { DataTable } from 'src/modules/Users/AllUsers/components/data-table'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './core/QueryResponseProvider'
import DateInput from 'src/shadcn/components/custom/DateInpute/DateInput'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import dayjs from 'dayjs';
import { useLocation, useSearchParams } from 'react-router-dom';
import Dropdown from 'src/shadcn/components/custom/Dropdown';
import { catchAsync, convertArrayToOptionType, IS_SUCCESS_RESPONSE } from 'src/helpers';
import { fetchAllUserRolesRequestApi } from 'src/services/requests/Users';
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area';

type Props = {

}

const Main = () => {
    const apiResp = useQueryResponseData()
    const { state, updateState } = useQueryRequest()
    const [value, onChange] = useState<Value>([dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()]);
    const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
        return {
            srNo: indx + 1,
            ...d,
        }
    }), [apiResp])
    const [roles, setRoles] = useState<any>([])
    const [isLoading, setisLoading] = useState<boolean>(false)

    useEffect(() => {
        handleFetchRoles()
        return () => { }
    }, [])


    const handleFetchRoles = catchAsync(async () => {
        setisLoading(true)
        return await fetchAllUserRolesRequestApi()
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setisLoading(false)
            setRoles(result.data)
        }
    }, () => { setisLoading(false) })


    console.log({ state: state.filter });


    return (
        <div className='p-4'>
            <ScrollArea className='h-[80vh]'>
                <div className='flex justify-end mb-4'>
                    <Dropdown value='' placeholder='Select Role' options={roles && [{ label: "All", value: undefined }, ...convertArrayToOptionType(roles)] || []} onChange={(e: any) => {
                        updateState({
                            ...state,
                            filter: {
                                ...state.filter,
                                role: e
                            },
                            page: 1,
                        })

                    }} />
                </div>

                <DataTable columns={columns} data={tableData || []} />
            </ScrollArea>
        </div>
    )
}

const AllUserLIstMain = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default AllUserLIstMain