import React from 'react'
import UserAnalysis from 'src/modules/Users/UserAnalysis/UserAnalysis'

type Props = {}

const UserDashboardMain = (props: Props) => {

    return (
        <div>
            <UserAnalysis />
        </div>
    )
}

export default UserDashboardMain