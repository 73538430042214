import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import { USER_INIT } from 'src/modules/Users/modules'
import UserReviewForm from 'src/modules/Users/UserDetails/UserReviewForm'
import { getUsersDetailsByUserIdRequestApi } from 'src/services/requests/Users'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/shadcn/components/ui'
import AllProjectLIstByUserMain from '../ProjectByUser/AllProjectLIstByUserMain'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'

type Props = {}

const UserDetailsMain = (props: Props) => {

    const { id } = useParams()
    const [data, setdata] = useState<any>(USER_INIT)
    const [isLoading, setisLoading] = useState<boolean>(false)

    useEffect(() => {
        handleFetchUserDetails()
        return () => { }
    }, [])


    const handleFetchUserDetails = catchAsync(async () => {
        setisLoading(true)
        return id && getUsersDetailsByUserIdRequestApi(id)
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            setisLoading(false)
            setdata(result.data)
        }
    }, () => { setisLoading(false) })

    return (
        <ScrollArea className='h-[80vh]'>
            <UserReviewForm data={data} />
            <Tabs defaultValue="projects" className="">
                <TabsList>
                    <TabsTrigger value="projects">Projects</TabsTrigger>
                    {/* <TabsTrigger value="enquiries">Enquiries</TabsTrigger> */}
                </TabsList>
                <TabsContent value="projects">
                    <AllProjectLIstByUserMain filter={{
                        channelPartnerId: data?._id 
                    }} />
                </TabsContent>
                <TabsContent value="enquiries"></TabsContent>
            </Tabs>

            <LoadingDialog isOpen={isLoading} />
        </ScrollArea>
    )
}

export default UserDetailsMain