import axios, { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/campagains";



export function getUserEngagmentAnalysisApiRequest(): Promise<AxiosResponse> {
    return axiosInstance.get(API.USER_MANAGEMENT_ANALYSIS).then((d: AxiosResponse) => d.data);
}



export function getUserEngagmentUserWiseReportApiRequest(): Promise<AxiosResponse> {
    return axiosInstance.get(API.USER_MANAGEMENT_USER_WISE_REPORT).then((d: AxiosResponse) => d.data);
}
