
import React, { useMemo } from 'react'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './core/QueryResponseProvider'
import { DataTable } from 'src/modules/ProjectEnquiry/ScheduleCallRequests/components/data-table'
import { columns } from 'src/modules/ProjectEnquiry/ScheduleCallRequests/components/columns'
import ComboBox from 'src/shadcn/components/custom/ComboBox'
import { convertArrayToOptionType } from 'src/helpers'
import { Tabs, TabsList, TabsTrigger } from 'src/shadcn/components/ui/tabs-og'

type Props = {

}

const Main = () => {
    const apiResp = useQueryResponseData()

    const { state, updateState } = useQueryRequest()
    const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
        return {
            srNo: indx + 1,
            ...d,
        }
    }), [apiResp])

    return (
        <div className='p-4'>
            <div className='flex justify-end my-2'>
                <Tabs defaultValue="PENDING" value={state.filter?.status} onValueChange={(e: any) => updateState({
                    filter: {
                        call_status: e
                    }
                })} className="">
                    <TabsList>
                        {/* <TabsTrigger value="PENDING">PENDING</TabsTrigger>
                        <TabsTrigger value="COMPLETED">COMPLETED</TabsTrigger>
                        <TabsTrigger value="REJECTED">REJECTED</TabsTrigger> */}
                        {
                            ["PENDING", "COMPLETED", "ACCEPT", "REJECTED", "INTERESTED", "NOT-INTERESTED"].map((d: any) => {
                                return <TabsTrigger value={d}>{d}</TabsTrigger>
                            })
                        }
                    </TabsList>

                </Tabs>
            </div>
            <DataTable columns={columns} data={tableData || []} />
        </div>
    )
}

const ScheduleCallRequestMain = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default ScheduleCallRequestMain