import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { APP_ROUTES } from 'src/navigations/modules'
import LandEnquiryMain from './LandEnquiry/LandEnquiryMain'
import NewConstructionEnquiryMain from './NewConstructionEnquiry/NewConstructionEnquiryMain'
import ProjectEnquiryDashboard from './ProjectEnquiryDashbaord/ProjectEnquiryDashboard'
import RooftopEnquiryMain from './RoofTopEnquiry/RooftopEnquiryMain'
import SellProjectEnquiryMain from './SellProjectEnquiry/SellProjectEnquiryMain'
import ScheduleCallRequestMain from './ScheduleCallRequests/ScheduleCallRequestMain'
import ProjectInterestRequestMain from './ProjectInterestRequests/ProjectInterestRequestMain'
import ServicesRequestMain from './ServiceRequests/ServicesRequestMain'
import FromEmailReportPage from './FromEmail/FromEmailReportPage'
import NewsLettersReportPage from './Newsletters/NewsLettersReportPage'
import RaisedEnquiry from './RaisedEnquiry/RaisedEnquiry'


type Props = {}

const AdminProjectEnquiryRoutes = (props: Props) => {
  const PROJECTS_ROUTES = APP_ROUTES.projectEnquiry.subRoutes

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route index element={<ProjectEnquiryDashboard />} />
        <Route path={PROJECTS_ROUTES.dashboard.route} element={<ProjectEnquiryDashboard />} />
        <Route path={PROJECTS_ROUTES.landEnquiry.route} element={<LandEnquiryMain />} />
        <Route path={PROJECTS_ROUTES.newConstructionEnquiry.route} element={<NewConstructionEnquiryMain />} />
        <Route path={PROJECTS_ROUTES.roofTopEnquiry.route} element={<RooftopEnquiryMain />} />
        <Route path={PROJECTS_ROUTES.sellProjectEnquiry.route} element={<SellProjectEnquiryMain />} />
        <Route path={PROJECTS_ROUTES.scheduleCallsRequests.route} element={<ScheduleCallRequestMain />} />
        <Route path={PROJECTS_ROUTES.projectInterestRequests.route} element={<ProjectInterestRequestMain />} />
        <Route path={PROJECTS_ROUTES.servicesRequest.route} element={<ServicesRequestMain />} />
        <Route path={PROJECTS_ROUTES.projectInterestRequests.route + "/:id"} element={<ProjectInterestRequestMain />} />
        <Route path={PROJECTS_ROUTES.emailRequest.route} element={<FromEmailReportPage />} />
        <Route path={PROJECTS_ROUTES.raisedEnquiry.route} element={<RaisedEnquiry />} />
        <Route path={PROJECTS_ROUTES.newsLetters.route} element={<NewsLettersReportPage />} />

        <Route path={"*"} element={<Navigate to={"/" + PROJECTS_ROUTES.dashboard.fullRoute} />} />
      </Route>
    </Routes>
  )
}

export default AdminProjectEnquiryRoutes