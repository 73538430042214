import { ChangeEvent, useEffect, useMemo } from 'react'
import { columns } from 'src/modules/ProjectEnquiry/FromEmail/components/columns'
import { DataTable } from 'src/modules/ProjectEnquiry/FromEmail/components/data-table'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponse, useQueryResponseData } from './core/QueryResponseProvider'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { useLocation } from 'react-router-dom'
import Dropdown from 'src/shadcn/components/custom/Dropdown'
import { Input } from 'src/shadcn/components/ui'

type Props = {}

const Main = () => {
  const apiResp = useQueryResponseData()
 
  const { state, updateState } = useQueryRequest()
  console.log({ apiResp });

  const { search } = useLocation()
  const querykey = useMemo(() => search ? search.replace("?", "").split("=")[1].replace("_true", "") : null, [search])

  console.log({ querykey });

  useEffect(() => {
    if (querykey) {
      updateState({
        filter: {
          type: querykey
        }
      })
    }

    return () => { }
  }, [querykey])



  const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
    return {
      srNo: indx + 1,
      ...d,
    }
  }), [apiResp])

  return (
    <div className='p-4'>
      <ScrollArea className='h-[80vh]'>
        <div className='flex justify-end mb-4'>
          <div className='flex space-x-2 items-center'>
            <Input type='text' placeholder='Search email/phone number' onChange={(e: ChangeEvent<HTMLInputElement>) => {

              let input_value = e.target.value.trim()

              if (input_value == "") {
                updateState({
                  filter: {
                    ...state.filter,
                    mobileNumber: undefined,
                    email: undefined
                  }
                })
              }
              else {
                // if (e.target.value.trim() !== '') {
                if (!isNaN(input_value as any)) {
                  updateState({
                    filter: {
                      ...state.filter,
                      mobileNumber: input_value
                    }
                  })
                }
                else {
                  updateState({
                    filter: {
                      ...state.filter,
                      email: input_value
                    }
                  })
                }
              }


              // }
            }} className='w-[200px]' />
            <Dropdown options={[{
              label: "All",
              value: "delivered"
            }, {
              label: "Interested To Register",
              value: "clickedOnJoinUs"
            }, {
              label: "Interested To Explore Marketplace",
              value: "clickedOnExploreMarketplace"
            }]} onChange={(e: any) => {
              updateState({
                filter: {
                  ...state.filter,
                  type: e
                }
              })
            }} value='' />
          </div>
        </div>
        <DataTable columns={columns} data={tableData || []} />
      </ScrollArea>
    </div>
  )
}

const FromEmailReportPage = (props: Props) => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export default FromEmailReportPage