import React from 'react'
import GlobalUserEngagementAnalysis from 'src/modules/Campaigns/UserEngagments/Dashboard/Analysis/GlobalUserEngagementAnalysis'
import UserWiseReportMainPage from '../../UserWiseReport/Report/UserWiseReportMainPage'

type Props = {}

const UserEngagementAanlysisMain = (props: Props) => {
    return (
        <div className='p-4'>
            <GlobalUserEngagementAnalysis />
            <UserWiseReportMainPage />
        </div>
    )
}

export default UserEngagementAanlysisMain