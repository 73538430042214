import { ColumnDef } from "@tanstack/react-table";
import { UserWiseReport } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import Actions from "./Actions";
import { checkDecimal } from "src/helpers";
import dayjs from "dayjs";
import { Badge } from "src/shadcn/components/ui";

export const columns: ColumnDef<UserWiseReport>[] = [
  {
    accessorKey: "srNo",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Sr.No. (#)" />
    ),
  },
  {
    accessorKey: "firstName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell({ row: { original: { firstName, lastName } } }) {
      return firstName + " " + lastName
    },
  },
  {
    accessorKey: "intrested_projects",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Interested Projects" />
    ),
    cell({ row: { original: { intrested_projects } } }) {
      return intrested_projects.join(" , ")
    },
  },

];
