import { useNavigate } from "react-router-dom"
import { APP_ROUTES, MAIN_ROUTES } from "src/navigations/modules"

export const ANALYSIS_DATA = [

  {
    title: "Total Rooftop Requests",
    value: "0",
    dataKey: "rooftopData",
    prefix: "#",
    icon: null
  },
  {
    title: "Total Land Requests",
    value: "0",
    dataKey: "landData",
    prefix: "#",
    icon: null
  },
  {
    title: "Total New Construction Requests ",
    value: "0",
    dataKey: "newconstructionData",
    prefix: "#",
    icon: null
  },
  {
    title: "Total Sell Project Request",
    value: "0",
    dataKey: "sellProjectsData",
    prefix: "#",
    icon: null
  },
]

export const SCHEDULE_CALL_REQUESTS_ANALYSIS_DATA = [

  {
    title: "Pending",
    value: "0",
    dataKey: "PENDING",
    prefix: "#",
    icon: null
  },
  {
    title: "Accepted",
    value: "0",
    dataKey: "ACCEPT",
    prefix: "#",
    icon: null
  },
]




export const EMAIL_VISITS_ANALYSIS_DATA = [

  {
    title: "Email Delivered",
    value: "0",
    dataKey: ["delivered_true","delivered_false"],
    prefix: "#",
    icon: null,
   
  },
  {
    title: "Email Seen",
    value: "0",
    dataKey: "seen_true",
    prefix: "#",
    icon: null,
   
  },
  // {
  //   title: "Interested for WhatsApp Enquiry",
  //   value: "0",
  //   dataKey: "",
  //   prefix: "#",
  //   icon: null
  // },
  {
    title: "Interested To Register",
    value: "0",
    dataKey: "clickedOnJoinUs_true",
    prefix: "#",
    icon: null
  },
  {
    title: "Interested To Explore Marketplace",
    value: "0",
    dataKey: "clickedOnExploreMarketplace_true",
    prefix: "#",
    icon: null
  },
  {
    title: "Total Subscribed",
    value: "0",
    dataKey: "totalNewsLetters",
    prefix: "#",
    icon: null
  },

  {
    title: "Raised Enquiry",
    value: "0",
    dataKey: "contactUsCount",
    prefix: "#",
    icon: null
  },
]