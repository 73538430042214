import { EMAIL_REGEX, FormField, NUMBER_REGEX, YES_NO_OPTIONS } from "src/helpers";

export interface User {
    firstName: string;
    lastName: string;
    mobileNumber: string;
    email: string;
    abouteMe: string;
    role: string;
    userid: string;
    referal_link: string;
    referralCode: string;
}

export const USER_INIT: User = {
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    abouteMe: "",
    role: "",
    userid: "",
    referal_link: "",
    referralCode: "",

};


export const USER_FORM_FIELDS: FormField[] = [
    {
        label: 'First Name',
        dataKey: 'firstName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'First Name',
            required: true
        },
    },
    {
        label: 'Last Name',
        dataKey: 'lastName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Last Name',
            required: true
        },
    },
    {
        label: 'Mobile Number',
        dataKey: 'mobileNumber',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Mobile Number',
            regex: NUMBER_REGEX, // Define this regex based on your requirements
            maxLength: 10,
            required: true
        },
    },
    {
        label: 'Email',
        dataKey: 'email',
        componentType: 'input',
        componentProps: {
            type: "email",
            placeholder: 'Email',
            required: true,
            regex: EMAIL_REGEX // Define this regex based on your requirements
        },
    },
    {
        label: 'About Me',
        dataKey: 'abouteMe',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Tell us about yourself',
            required: false // Optional field
        },
    },
    {
        label: 'Role',
        dataKey: 'role',
        componentType: 'options',
        componentProps: {
            options: [], // Define this array based on your requirements
            required: true
        },
    },

    {
        label: 'User  ID',
        dataKey: 'userid',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'User  ID',
            required: true
        },
    },
    {
        label: 'Referral Link',
        dataKey: 'referal_link',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Referral Link',
            required: false // Optional field
        },
    },
    {
        label: 'Referral Code',
        dataKey: 'referralCode',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Referral Code',
            required: false // Optional field
        },
    },

];