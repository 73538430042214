import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, convertToCamelCase, IS_SUCCESS_RESPONSE } from 'src/helpers'
import AnalysisTiles from 'src/modules/Dashboard/AnalysisTiles'
import { APP_ROUTES, MAIN_ROUTES } from 'src/navigations/modules'
import { getUsersAnalysisRequestApi } from 'src/services/requests/Users'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'

type Props = {}

const UserAnalysis = (props: Props) => {


    const [data, setData] = useState<any[]>([])
    const [isLoading, setisLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        handleFetchUserAnalysis()
        return () => { }
    }, [])


    const handleFetchUserAnalysis = catchAsync(async () => {
        setisLoading(true)
        return await getUsersAnalysisRequestApi()
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            result?.data && result.data.length > 0 && setData(result.data.map((d: any) => {
                return {
                    title: convertToCamelCase(d?._id),
                    value: d?.count,
                    dataKey: "",
                    prefix: "#",
                    icon: null,
                    action: () => navigate("/" + APP_ROUTES.users.route + "?role=" + d?._id)
                }
            }))
        }
        setisLoading(false)
    }, () => { setisLoading(false) })

    return (
        <div className='p-4'>
            <div className='text-primary uppercase mb-4'>Users Analysis</div>
            <div className='grid grid-cols-4 gap-4'>
                {
                    data && data.length > 0 && data.map((item: any, index: number) => {
                        return <AnalysisTiles {...item} />
                    })
                }
            </div>
            <LoadingDialog isOpen={isLoading} />
        </div>
    )
}

export default UserAnalysis