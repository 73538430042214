import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, IS_SUCCESS_RESPONSE } from 'src/helpers'
import AnalysisTiles from 'src/modules/Dashboard/AnalysisTiles'
import { APP_ROUTES } from 'src/navigations/modules'
import { USER_ENGAGEMENT_ANALYSIS } from './modules'
import { getUserEngagmentAnalysisApiRequest } from 'src/services/requests/campagains'

type Props = {}

const GlobalUserEngagementAnalysis = (props: Props) => {
    const [data, setdata] = useState(USER_ENGAGEMENT_ANALYSIS)

    const navigate = useNavigate()

    useEffect(() => {
        handleFetchSummaryDetails()
        return () => { }
    }, [])

    const handleFetchSummaryDetails = catchAsync(async () => await getUserEngagmentAnalysisApiRequest(), (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            if (result.data) {
                setdata(data.map((d: any) => {
                    if (result.data && Object.keys(result.data).includes(d?.dataKey)) {
                        return {
                            ...d,
                            value: result.data[d?.dataKey as keyof object] || 0
                        }
                    }
                }))
            }

        }
    }, () => { })



    return (
        <div className=''>
            <div className='my-2 flex justify-between'>
                <div className='font-semibold text-primary uppercase'>Reports Summary</div>
                {/* <button onClick={() => navigate("/" + APP_ROUTES.sales_executive.subRoutes.reports.subRoutes.all.fullRoute)} className='underline underline-offset-2 text-gray-500 hover:text-primary text-sm cursor-pointer mx-4'>View More</button> */}
            </div>
            <div className={`grid gap-4 md:grid-cols-3 lg:grid-cols-3`}>
                {
                    data.map((d: any) => {
                        return <AnalysisTiles {...d} />
                    })
                }
            </div>
        </div>
    )
}

export default GlobalUserEngagementAnalysis