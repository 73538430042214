export const USER_ENGAGEMENT_ANALYSIS = [
    {
      title: "Total Users",
      value: "0",
      dataKey: "totalUsers",
      prefix: "#",
      icon: ""
    },
    {
      title: "Total Email Sent",
      value: "0",
      dataKey: "emailSentCount",
      prefix: "#",
      icon: ""
    },
    {
      title: "Total Email Seen",
      value: "0",
      dataKey: "emailSeenCount",
      prefix: "#",
      icon: ""
    },
    {
      title: "Total WhatsApp Interested",
      value: "0",
      dataKey: "whatsApplinkClicked",
      prefix: "#",
      icon: ""
    },
    {
      title: "Total Projects Interested",
      value: "0",
      dataKey: "projectsInterestedCount",
      prefix: "#",
      icon: ""
    },
  ]